import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles, Theme } from '@material-ui/core/styles'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import chirpyestLogo from './../../assets/images/chirpyest-logo-front.svg'

interface BrandTooltipProps {
  cashback: string
  customText?: string
  commissionException: string
  children: React.ReactNode
  t: TFunction
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
}))(Tooltip)

const BrandTooltip = ({
  cashback,
  customText,
  commissionException,
  children,
  t,
}: BrandTooltipProps) => {
  const classes = styles()
  return (
    <HtmlTooltip
      title={
        <>
          <img
            src={chirpyestLogo}
            alt={t('shared.chirpyest')}
            className={classes.logo}
          />
          <h6 className={classes.title}>
            {cashback} {t('brands.tooltipTitle', { text: customText })}
            {commissionException && (
              <>
                {' '}
                <pre className={classes.preText}>{commissionException}</pre>
              </>
            )}
          </h6>
        </>
      }
      arrow
      placement="top"
    >
      {children}
    </HtmlTooltip>
  )
}

export default withTranslation()(BrandTooltip)
