import { Link } from 'gatsby'
import React from 'react'
import { generateTrackingLink } from '../../../utils/brandsHelper'
import { useAppState } from '../../appState'
import { CASHBACK_RANGES, CSEvents } from '../../constants'
import { tracker } from '../../systemLogs'
import CHRBrandsGroupItem from '../brandsGroupItem'
import Delayed from '../delayed'
import BrandTooltip from './../brandTooltip'
import { styles } from './styles'

interface BrandsGroupListProps {
  groupedList: any
  keysList: string[]
  selectedFilter: number
  userId: string | null
  featuredBrands: any
}

const CHRBrandsGroupList = ({
  groupedList,
  keysList,
  selectedFilter,
  userId,
  featuredBrands,
}: BrandsGroupListProps) => {
  const classes = styles()
  const [
    {
      settings: { commissionSplitPercent },
      userProfile: { userName },
    },
  ] = useAppState()
  let sortMethod: any

  const alphabeticalSort = (a: any, b: any) =>
    a.brandName.toLowerCase() > b.brandName.toLowerCase() ? 1 : -1
  const comissionAscSort = (a: any, b: any) => a.commission - b.commission
  const commissionDescSort = (a: any, b: any) => b.commission - a.commission

  if (selectedFilter == 1) {
    sortMethod = alphabeticalSort
  } else if (selectedFilter == 2) {
    sortMethod = comissionAscSort
  } else {
    sortMethod = commissionDescSort
  }

  const renderItems = (char: string) => {
    return (
      groupedList &&
      groupedList[char].sort(sortMethod).map((item: any, index: number) => {
        let commission = item.commission.includes('Fixed')
          ? item.commission.replace('Fixed ', 'Fixed $')
          : item.commission
        let customText =
          item?.customText !== undefined && item?.customText !== null
            ? item?.customText
            : 'cash back'
        if (commission.includes('$'))
          commission = `Fixed $${Number(
            commission.replace('Fixed $', '')
          ).toFixed(2)}`

        commission = `(${commission})`
        // some brands dont want to be known as giving cashback
        // and want to be kind of luxary brand
        // so we will not show the percent amount
        // and will not give user any cash back
        if (!item.isCashbackShownInWebsite) commission = ''

        const isDoubleCashback =
          Number(item.splitPercent) > commissionSplitPercent / 100
        return (
          <h3 className={classes.title} key={`${item.brandName}${index}`}>
            {featuredBrands[item.brandId] ? (
              <Link
                style={{ color: 'black' }}
                to={`/featured-brands/${item.brandName.replace(
                  /\s+|\/|\\|#/g,
                  '-'
                )}/${item.brandId}`}
              >
                {item.brandName}
              </Link>
            ) : (
              <a
                href={generateTrackingLink(
                  item,
                  { userId, userName },
                  '',
                  '',
                  Boolean(userId)
                )}
                target="_blank"
                rel="noreferrer"
                className={classes.title}
                onClick={() => {
                  tracker.trackCs(CSEvents.clickBrand, {
                    pageUrl: window.location.href,
                    brandId: item.brandId,
                    section: 'brands-page',
                  })
                }}
              >
                {item.brandName}
              </a>
            )}
            {/* 
              BrandTooltip rendering is very slow specially material Tooltip
              when we sort the 1k brnads it will cause all these slow tooltips to rerender
              making UI not unresponsive, so we initialy we render nothing then in a few milliseconds
              render the tooltips again
             */}
            <Delayed waitBeforeShow={10}>
              <BrandTooltip
                cashback={commission}
                customText={customText}
                commissionException={item.commissionException}
              >
                <span className={`${classes.percentage} ${classes.tealColour}`}>
                  {' '}
                  {commission}
                  {isDoubleCashback ? ` 💃🏿` : ''}
                </span>
              </BrandTooltip>
            </Delayed>
          </h3>
        )
      })
    )
  }

  if (selectedFilter == 2) {
    const keysListAsc = keysList.sort((x, y) =>
      isNaN(Number(y)) ? -1 : Number(x) - Number(y)
    )

    return (
      <div className={classes.container}>
        {keysListAsc.map((char: string) => {
          const range =
            CASHBACK_RANGES.find(ele => ele.min.toString() === char)?.range ||
            'others'

          return (
            <CHRBrandsGroupItem
              id={char}
              name={range}
              suffix={` (${groupedList[char].length})`}
              key={char}
            >
              {renderItems(char)}
            </CHRBrandsGroupItem>
          )
        })}
      </div>
    )
  }

  if (selectedFilter == 3) {
    const keysListDesc = keysList.sort((x, y) =>
      isNaN(Number(y)) ? -1 : Number(y) - Number(x)
    )

    return (
      <div>
        {keysListDesc.map((char: string) => {
          const range =
            CASHBACK_RANGES.find(ele => ele.min.toString() === char)?.range ||
            'others'

          return (
            <CHRBrandsGroupItem
              id={char}
              name={range}
              suffix={` (${groupedList[char].length})`}
              key={char}
            >
              {renderItems(char)}
            </CHRBrandsGroupItem>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      {keysList.map(char => {
        return (
          <CHRBrandsGroupItem
            id={char}
            name={char}
            suffix={` (${groupedList[char].length})`}
            key={char}
          >
            {renderItems(char)}
          </CHRBrandsGroupItem>
        )
      })}
    </div>
  )
}

export default CHRBrandsGroupList
