import React, { useEffect, useState } from 'react'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { FormControl } from '@material-ui/core'
import Select from 'react-select'
import { BRANDS_CATEGORIES, ROUTES } from '../../constants'
import { navigate } from 'gatsby'

interface BrandsCategorySortProps {
  selectedFilter: number
  onFilterChange: () => void
  t: TFunction
}

const BrandsCategorySort = ({
  t,
  onFilterChange,
  selectedFilter,
  ...props
}: BrandsCategorySortProps) => {
  const classes = styles()
  const categoriesKeys = Object.keys(BRANDS_CATEGORIES)

  const [selectedOption, setSelectedOption] = useState({
    value: 1,
    label: t('shared.all'),
  })
  const [hasFilterChanged, setHasFilterChanged] = useState(false)

  const options: { value: number; label: string }[] = [
    { value: 1, label: t('shared.all') },
    { value: 2, label: t('shared.fashion') },
    { value: 3, label: t('shared.beauty') },
    { value: 4, label: t('shared.home') },
    { value: 5, label: t('shared.lifestyle') },
    { value: 6, label: t('shared.travel') },
    { value: 7, label: t('shared.fitness') },
    { value: 8, label: t('shared.tech') },
    { value: 9, label: t('shared.pets') },
    // { value: 10, label: t('shared.kids') },
    { value: 11, label: t('shared.healthWellness') },
    // { value: 12, label: t('shared.ecoFriendly') },
    { value: 13, label: t('shared.luxury') },
    { value: 14, label: t('shared.jewelry') },
  ]

  const handleChange = (category: React.Event) => {
    setSelectedOption(category)
    setHasFilterChanged(prevState => !prevState)
    navigate(`${ROUTES.brands}/#${category.label}`)
  }

  useEffect(() => {
    if (location.href.includes('#')) {
      const valueAfterHash = decodeURI(location.hash.split('#')[1])
      if (categoriesKeys.includes(valueAfterHash)) {
        const categoryLabel = valueAfterHash
        const categoryValue: number = BRANDS_CATEGORIES[categoryLabel].value
        const category = { value: categoryValue, label: categoryLabel }
        setSelectedOption(category)
        onFilterChange(categoryValue)
      }
    }
  }, [hasFilterChanged])

  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      width: '210px',
      padding: 0,
      marginLeft: 10,
      cursor: 'pointer',
      border: 'none !important',
      boxShadow: 'none',
      '&:hover': {
        border: 'none !important',
      },
    }),

    option: styles => {
      return {
        ...styles,
        fontFamily: 'Schnyder XL Light',
        fontSize: 30,
        padding: '0 0 0 10px',
      }
    },

    valueContainer: styles => ({
      height: 50,
    }),

    singleValue: styles => ({
      ...styles,
      top: 'unset',
      bottom: -25,
      fontFamily: 'Schnyder XL Light',
      fontSize: '1.875rem',
      fontWeight: 400,
    }),

    placeholder: styles => ({
      display: 'none',
    }),

    input: styles => ({
      color: 'transparent',
    }),

    menu: styles => ({
      ...styles,
      width: 200,
    }),
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <h4 className={classes.sortByText}>{t('shared.category')}: </h4>
        <Select
          defaultValue={selectedOption}
          value={selectedOption}
          className={classes.selectList}
          onChange={handleChange}
          options={options}
          styles={colourStyles}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
        />
      </FormControl>
    </div>
  )
}

export default withTranslation()(BrandsCategorySort)
